<template>
<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">
                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">
                            <h3 class="vv">Billing</h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 bg-accent border-bottom" v-show="ready">
            <ul class="nav nav-tabs px-0 mx-0" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Subscription</a>
                </li>

            </ul>
        </div>
        <div class="col-12" v-show="ready">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active px-2 pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row justify-content-center">
                        <div class="col-lg-4">
                            <div class="card p-4">
                                <h4>Trillwave Pro</h4>

                                <a :href="url" class="lemonsqueezy-button btn btn-lg btn-primary btn-block">Get Started</a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<style>
@import "../assets/css/ql.css";
</style>

<script>
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import {
    VueEditor
} from "vue2-editor";
export default {
    name: "LicencesNew",
    components: {
        VueEditor,
    },
    data() {
        return {
            ready: true,
            url: "#",
            saving: false,
            validationErrors: [],
            settings: {
                paypal_email: "",

            },
        };
    },
    mounted() {
        this.ready = false;
        this.generate_url();
        this.get_settings();
        window.createLemonSqueezy();

        //$('#file_modal').modal('show')
    },
    methods: {
        generate_url() {
            let email = this.$store.getters.getUser.email;
            let user_id = this.$store.getters.getUser.id;
            this.url = `https://trillwave.lemonsqueezy.com/checkout/buy/2b0987d2-ccf7-422a-a80b-89a954c507a9?checkout[email]=${email}&embed=1&media=0&dark=1&checkout[custom][user_id]=${user_id}`
        },
        get_settings() {
            var baseURI = this.$baseurl + "/settings";
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };

            this.$http
                .get(baseURI, {
                    headers: config,
                })
                .then((result) => {
                    if (result.data.message === "invalid token") {} else {}
                    if (result.data) {
                        this.settings = result.data;
                    }

                    //this.keys = Object.keys(this.res[0]);
                });
            this.ready = true;
        },
        cancel() {
            this.$router.push("/d/overview");
        },
        verify() {
            var send = this.settings;

            var baseURI,
                msg = "";
            baseURI = this.$baseurl + "/settings";

            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };

            this.$http
                .post(baseURI, send, {
                    headers: config,
                })
                .then((result) => {
                    this.process(result, msg);
                    //this.keys = Object.keys(this.res[0]);
                });

        },
        process(result, msg) {
            if (result.data.error) {
                this.validationErrors.push({
                    message: result.data.message,
                });
                Snackbar.show({
                    text: result.data.message,
                    customClass: "snack-danger",
                    actionText: "DISMISS",
                });
                this.saving = false;
            } else {
                Snackbar.show({
                    text: "settings updated",
                    customClass: "snack-success",
                    actionText: "OK",
                });
                this.saving = false;
                this.$router.push("/d/overview");
            }
        },
    },
};
</script>

<style>
</style>
